import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@utils/cn";

const badgeVariantsClasses = cva(
  "inline-flex items-center border h-fit w-fit transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground border border-gray-200 hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline:
          "bg-gray-200 border border-gray-300 text-sm text-primary font-medium",
        success:
          "border-transparent bg-emerald-500 text-white hover:bg-emerald-500/80",
        inverted: "border-transparent bg-background text-primary",
      },
      shape: {
        rounded: "rounded-lg",
        pill: "rounded-full",
        square: "rounded-md",
      },
      size: {
        default: "px-3 py-2 text-base",
        sm: "h-6 font-medium text-sm px-3 py-2",
        lg: "px-4 py-3 text-lg",
        xs: "px-1.5 py-1 text-xs",
      },
    },
    defaultVariants: {
      variant: "default",
      shape: "rounded",
      size: "sm",
    },
  },
);
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariantsClasses> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, shape, size, ...props }, _ref) => {
    return (
      <div
        className={cn(badgeVariantsClasses({ variant, shape, size }), className)}
        {...props}
      />
    );
  },
);
Badge.displayName = "Badge";
export { Badge, badgeVariantsClasses as badgeVariants };
