"use client";
import { cn } from "@utils/cn";
import React, { cloneElement, forwardRef, ReactElement, useState } from "react";

export interface WobbleProps {
  children: ReactElement;
  className?: string;
  scale?: number;
}

const Wobble = forwardRef<HTMLElement, WobbleProps>(
  ({ children, className, scale = 1.02 }, ref) => {
    const [isHovering, setIsHovering] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const hasTranslateClass = /translate/.test(children.props.className || "");
    if (hasTranslateClass) return children;

    const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
      const { clientX, clientY } = event;
      const rect = event.currentTarget.getBoundingClientRect();
      const x = (clientX - (rect.left + rect.width / 2)) / 20;
      const y = (clientY - (rect.top + rect.height / 2)) / 20;
      setMousePosition({ x, y });
      children.props.onMouseMove?.(event);
    };

    const handleMouseEnter = () => {
      setIsHovering(true);
      children.props.onMouseEnter?.();
    };
    const handleMouseLeave = () => {
      setIsHovering(false);
      setMousePosition({ x: 0, y: 0 });
      children.props.onMouseLeave?.();
    };

    const childStyles: React.CSSProperties = {
      transform: isHovering
        ? `translateX(${mousePosition.x}px) translateY(${mousePosition.y}px) scale(${scale})`
        : "",
    };

    return cloneElement(children, {
      ref,
      onMouseMove: handleMouseMove,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      style: {
        ...childStyles,
        ...children.props.style,
      },
      className: cn(className, children.props.className,
        "transition ease-out"
      ),
    });
  },
);

Wobble.displayName = "Wobble";

export { Wobble };
