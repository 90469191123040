"use client";

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";
import * as React from "react";

import { cn } from "@utils/cn";
import { Label } from "./label";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;
const RadioGroupPrimitiveItem = RadioGroupPrimitive.Item;
const RadioGroupIndicator = RadioGroupPrimitive.Indicator;
const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitiveItem>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitiveItem>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitiveItem
      ref={ref}
      className={cn(
        "aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitiveItem>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;


const LabledRadioGroupItem = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> & { value: string }
>(({ className, children, value, ...props }, ref) => {
  const id = React.useId()
  return (
    <div ref={ref} {...props} className="flex items-center gap-2">
      <RadioGroupPrimitiveItem value={value} />
      <label htmlFor={id} className="text-sm font-medium text-muted-foreground">
        {children}
      </label>
    </div>
  );
});

LabledRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;


export {
  RadioGroup,
  RadioGroupItem,
  RadioGroupPrimitiveItem,
  RadioGroupIndicator,
  LabledRadioGroupItem
};
