"use client";
import { VariantProps } from "class-variance-authority";
import React from "react";
import { toast } from "sonner";
import { uniqueId } from "lodash";
import { TagList } from "./tagList";
import { tagVariants } from "./tag";
import { cn } from "@utils/cn";
import { Input } from "..";

export enum Delimiter {
  Comma = ",",
  Enter = "Enter",
  Space = " ",
}

type OmittedInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "value"
>;

export type Tag = {
  id: string;
  text: string;
};

export interface TagInputProps
  extends OmittedInputProps,
    VariantProps<typeof tagVariants> {
  placeholder?: string;
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  maxTags?: number;
  minTags?: number;
  readOnly?: boolean;
  disabled?: boolean;
  onTagAdd?: (tag: string) => void;
  onTagRemove?: (tag: string) => void;
  allowDuplicates?: boolean;
  validateTag?: (tag: string) => boolean;
  delimiter?: Delimiter;
  showCount?: boolean;
  placeholderWhenFull?: string;
  sortTags?: boolean;
  delimiterList?: string[];
  truncate?: number;
  minLength?: number;
  maxLength?: number;
  value?: string | number | readonly string[] | { id: string; text: string }[];
  direction?: "row" | "column";
  onInputChange?: (value: string) => void;
  customTagRenderer?: (tag: Tag) => React.ReactNode;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onTagClick?: (tag: Tag) => void;
  draggable?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  tagsPlacement?: "inline" | "top";
}

const TagInput = React.forwardRef<HTMLInputElement, TagInputProps>(
  (props, ref) => {
    const {
      id,
      placeholder,
      tags,
      setTags,
      variant,
      size,
      shape,
      className,
      maxTags,
      delimiter = Delimiter.Comma,
      onTagAdd,
      onTagRemove,
      allowDuplicates,
      validateTag,
      placeholderWhenFull = "Max tags reached",
      sortTags,
      delimiterList,
      truncate,
      borderStyle,
      textCase,
      interaction,
      animation,
      textStyle,
      minLength,
      maxLength,
      onInputChange,
      customTagRenderer,
      onFocus,
      onBlur,
      onTagClick,
      tagsPlacement = "top",
      inputProps = {},
    } = props;

    const [inputValue, setInputValue] = React.useState("");
    const [tagCount, setTagCount] = React.useState(Math.max(0, tags.length));
    const inputRef = React.useRef<HTMLInputElement>(null);
    if (
      (maxTags !== undefined && maxTags < 0) ||
      (props.minTags !== undefined && props.minTags < 0)
    ) {
      console.warn("maxTags and minTags cannot be less than 0");
      toast.error("maxTags and minTags cannot be less than 0", {
        description:
          "Please set maxTags and minTags to a value greater than or equal to 0",
      });
      return null;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      onInputChange?.(newValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        delimiterList
          ? delimiterList.includes(e.key)
          : e.key === delimiter || e.key === Delimiter.Enter
      ) {
        e.preventDefault();
        const newTagText = inputValue.trim();

        if (validateTag && !validateTag(newTagText)) {
          return;
        }

        if (minLength && newTagText.length < minLength) {
          console.warn("Tag is too short");
          toast.error("Tag is too short", {
            description: "Please enter a tag with more characters",
          });
          return;
        }

        // Validate maxLength
        if (maxLength && newTagText.length > maxLength) {
          toast.warning("Tag is too long", {
            description: "Please enter a tag with less characters",
          });
          console.warn("Tag is too long");
          return;
        }

        const newTagId = uniqueId();

        if (
          newTagText &&
          (allowDuplicates || !tags.some((tag) => tag.text === newTagText)) &&
          (maxTags === undefined || tags.length < maxTags)
        ) {
          setTags([...tags, { id: newTagId, text: newTagText }]);
          onTagAdd?.(newTagText);
          setTagCount((prevTagCount) => prevTagCount + 1);
        }
        setInputValue("");
      }
    };

    const removeTag = (idToRemove: string) => {
      setTags(tags.filter((tag) => tag.id !== idToRemove));
      onTagRemove?.(tags.find((tag) => tag.id === idToRemove)?.text || "");
      setTagCount((prevTagCount) => prevTagCount - 1);
    };

    const displayedTags = sortTags ? [...tags].sort() : tags;

    const truncatedTags = truncate
      ? tags.map((tag) => ({
          id: tag.id,
          text:
            tag.text?.length > truncate
              ? `${tag.text.substring(0, truncate)}...`
              : tag.text,
        }))
      : displayedTags;

    if (tagsPlacement === "inline") {
      return (
        <div className="flex flex-wrap items-center reset-input p-1 flex-1 overflow-auto h-auto">
          <div className="flex items-center flex-wrap gap-1">
            <TagList
              tags={truncatedTags}
              customTagRenderer={customTagRenderer}
              variant={variant}
              size={size}
              shape={shape}
              borderStyle={borderStyle}
              textCase={textCase}
              interaction={interaction}
              animation={animation}
              textStyle={textStyle}
              onTagClick={onTagClick}
              onRemoveTag={removeTag}
            />
          </div>
          <input
            ref={inputRef}
            id={id}
            type="text"
            placeholder={
              maxTags !== undefined && tags.length >= maxTags
                ? placeholderWhenFull
                : placeholder
            }
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            className={cn("flex-1 min-w-10 p-1", className)}
            disabled={maxTags !== undefined && tags.length >= maxTags}
            {...inputProps}
          />
        </div>
      );
    } else if (tagsPlacement === "top") {
      return (
        <div className="w-full flex flex-col items-start gap-2">
          <div className="flex items-center flex-wrap gap-1 w-full">
            <TagList
              tags={truncatedTags}
              customTagRenderer={customTagRenderer}
              variant={variant}
              size={size}
              shape={shape}
              borderStyle={borderStyle}
              textCase={textCase}
              interaction={interaction}
              animation={animation}
              textStyle={textStyle}
              onTagClick={onTagClick}
              onRemoveTag={removeTag}
            />
          </div>
          <Input
            ref={inputRef}
            id={id}
            type="text"
            placeholder={
              maxTags !== undefined && tags.length >= maxTags
                ? placeholderWhenFull
                : placeholder
            }
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            className={cn("flex-1 w-full", className)}
            disabled={maxTags !== undefined && tags.length >= maxTags}
            {...inputProps}
          />
        </div>
      );
    }
  },
);

TagInput.displayName = "TagInput";

export { TagInput };
