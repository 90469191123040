import React from "react";
import { type Tag as TagType } from "./input";
import { TagComponent, TagProps } from "./tag";

export type TagListProps = {
  tags: TagType[];
  customTagRenderer?: (tag: TagType) => React.ReactNode;
  direction?: TagProps["direction"];
} & Omit<TagProps, "tagObj">;

export const TagList: React.FC<TagListProps> = ({
  tags,
  customTagRenderer,
  ...tagListProps
}) => {
  return tags.map((tagObj) =>
    customTagRenderer ? (
      customTagRenderer(tagObj)
    ) : (
      <TagComponent key={tagObj.id} tagObj={tagObj} {...tagListProps} />
    ),
  );
};
