import { cn } from "@utils/cn";
import React from "react";

function Skeleton({
  className,
  isLoading,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { isLoading?: boolean }) {
  if (!isLoading) {
    return <>{children}</>;
  }
  return (
    <div
      className={cn("animate-pulse rounded-md bg-muted w-full", className)}
      {...props}
    />
  );
}

export { Skeleton };
