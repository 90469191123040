"use client";

import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { cn } from "@utils/cn";
import { cva, VariantProps } from "class-variance-authority";
const avatarVariants = cva("relative flex h-10 w-10 shrink-0 overflow-hidden", {
  variants: {
    size: {
      small: "h-6 w-6 text-xs",
      medium: "h-8 w-8 text-sm",
      large: "h-10 w-10 text-base",
      xlarge: "h-12 w-12 text-base",
      xxlarge: "h-14 w-14 text-base",
    },
    bg: {
      blue: "bg-blue-500",
      yellow: "bg-yellow-500",
      red: "bg-red-500",
      rose: "bg-rose-500",
      black: "bg-black text-white",
    },
    rounded: {
      full: "rounded-full",
      lg: "rounded-lg",
      md: "rounded-md",
      sm: "rounded-sm",
    },
    loading: {
      true: "animate-pulse",
    },
  },
  defaultVariants: {
    size: "medium",
    bg: "blue",
    rounded: "full",
  },
});
const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> &
    VariantProps<typeof avatarVariants>
>(({ className, size, bg, rounded, loading, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarVariants({ size, bg, rounded, loading }), className)}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    data-fallback
    className={cn(
      "flex size-full items-center justify-center text-[1em] rounded-full bg-transparent text-white font-semibold",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

interface ComposedAvatarProps extends VariantProps<typeof avatarVariants> {
  initials: string;
  imageSrc?: string;
}
function ComposedAvatar({
  size,
  imageSrc,
  initials,
  bg,
  rounded,
}: ComposedAvatarProps) {
  return (
    <Avatar size={size} rounded={rounded} bg={bg}>
      <AvatarImage src={imageSrc} alt={initials} />
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  );
}

export { Avatar, AvatarImage, AvatarFallback, ComposedAvatar };
